<template>
	<div class="page">
		<van-radio-group v-model="checked">
			<radio
					v-for="(item, index) in officialGroup"
					:key="item.officalId"
					:name="item.officalId"
			>
				<div class="item-context  flex-1">
					<div class="flex h-between">
						<div class="fs-14 flex-1 text-flow">{{ item.title }}</div>
						<div class="flex-right flex" @click.stop="handleTargetPreview(item)">
							<i class="icon-preview mr-1"/>
							<span class="color-primary " >预览</span>
						</div>
					</div>
					<div class="group-info mt-2 fs-12">
						{{ item.desc ? item.desc : '暂未设置群介绍' }}
					</div>
				</div>
			</radio>
			<radio
					v-for="(item, index) in officialMaterial"
					:key="item.chatRoomId"
					:name="item.chatRoomId"
			>
				<div class="item-context  flex-1">
					<div class="flex h-between">
						<div class="fs-14 flex-1 text-flow">{{ item.groupName }}</div>
						<div class="flex-right flex" @click.stop="previewClick(item)">
							<i class="icon-preview mr-1"/>
							<span class="color-primary ">预览</span>
						</div>
					</div>
					<div class="group-info mt-2 fs-12">
						{{ item.desc ? item.desc : '暂未设置群介绍' }}
					</div>
				</div>
			</radio>
		</van-radio-group>
		<div class="p-footer h-end">
			<button class="btn auto" @click="$router.replace('/index')">取消</button>
			<button class="btn auto fill ml-3" @click="handleSubmit">确认提交</button>
		</div>
		<chat-list :officialMaterialInfo="officialMaterialInfo" v-model:show="chatListDialog"/>
	</div>

</template>

<script setup>
import { onMounted, ref } from 'vue'
import Radio from '../../components/PgyRadio'
import chatList from '../../components/chatList/index.vue'
import { Notify, Toast } from 'vant'
import {
	ApiOfficalPushGroupList,
	ApiQueryOfficialChatrooms,
	ApiSetFollowLiveChatroomToOfficalType
} from '../../api/FollowLive'
import { useRoute, useRouter } from 'vue-router'

const route = useRoute()
const router = useRouter()

const checked = ref('')
// 官方分组群
const officialGroup = ref([])
// 官方素材群
const officialMaterial = ref([])
// 官方素材信息
const officialMaterialInfo = ref({})

const chatListDialog = ref(false)
// 跟发群信息
const followGroupInfo = route.query || {}
onMounted(async () => {
	// 判断是否已设置
	if (followGroupInfo.officialChatroomSerialNo || followGroupInfo.officalId) {
		console.log(followGroupInfo)
		// 如果群id是 0  说明选中的是官方分组
		checked.value = followGroupInfo.officialChatroomSerialNo === '0'
				? followGroupInfo.officalId
				: followGroupInfo.officialChatroomSerialNo
	}
	const toast = Toast.loading({
		message: '数据加载中...',
		forbidClick: true,
		loadingType: 'spinner',
		duration: 0
	})
	await getOfficialGroup()
	await getOfficialMaterial()
	toast.clear()

})

// 获取官方分组群
const getOfficialGroup = async () => {
	try {
		const res = await ApiOfficalPushGroupList()
		officialGroup.value = res.map(i => {
			i.officalId = i.officalId + ''
			return i
		})
		console.log(officialGroup.value)
	} catch (e) {
		console.log(e)
	}
}
// 获取官方素材群
const getOfficialMaterial = async () => {
	try {
		const res = await ApiQueryOfficialChatrooms()
		officialMaterial.value = res.map(i => {
			i.groupName = i.showName ?
					i.showName :
					(i.chatRoomName ? i.chatRoomName : '未命名')
			return i
		})
	} catch (e) {
		console.log(e)
	}
}
const handleTargetPreview = (item) => {
	router.push({
		path: '/officialMaterial',
		query: {
			officalId: item.officalId
		}
	})
}

const previewClick = (item) => {
	officialMaterialInfo.value = {
		chatroomId: item.chatRoomId,
		robotWxId: item.robotWxId
	}
	chatListDialog.value = true
}

const handleSubmit = async () => {
	if (!checked.value) {
		Notify({
			type: 'warning',
			duration: 1000,
			message: '请先选择素材群！'
		})
		return
	}
	try {
		const isOfficialGroup = officialGroup.value.some(i => {
			return checked.value === i.officalId
		})
		let data = {
			officalId: isOfficialGroup ? checked.value : 0,
			officialChatroomSerialNo: !isOfficialGroup ? checked.value : 0
		}
		await ApiSetFollowLiveChatroomToOfficalType({
			...followGroupInfo,
			...data
		})
		Toast.success('设置成功！')
		await router.replace('/index')
	} catch (e) {
		console.log(e)
	}

}
</script>

<style scoped lang="scss">
.page {
	padding: px(15) px(15) px(70);
	box-sizing: border-box;

	:deep(.van-radio-group) {
		padding: 0 px(15);
		background: white;

		.van-radio__label {
			width: 100%;
		}

		.van-radio {
			align-items: baseline;
			padding: px(15) 0;
			border-bottom: 1px dashed #F1F1F1;

			&:last-of-type {
				border-top: 0;
			}
		}
	}
}

.item-context {
	width: 100%;
	color: #433C3C;

	.icon-preview {
		margin-top: px(-2);
		display: inline-block;
		width: px(18);
		height: px(18);
		mask-image: url("assets/icon-1.png");
		mask-repeat: no-repeat;
		mask-size: contain;
		mask-position: center;
		background: $color-primary;
	}

	.group-info {
		white-space: pre-line;
		padding: px(8);
		background: #F6F6F6;
		border-radius: px(4);
	}
}

</style>
